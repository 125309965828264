class window.HoverEffect
	constructor: (@htmlElem) ->
		@hoverDiv = document.createElement 'div'

		@hoverDiv.className = 'hover-effect hide'

		@htmlElem.appendChild(@hoverDiv)

		@events()

	showLine: ->
		@hoverDiv.classList.remove('hide')

	hideLine: ->
		@hoverDiv.classList.add('hide')

	events: ->
		@htmlElem.addEventListener('mouseenter', =>
			@showLine()
		,false)
		@htmlElem.addEventListener('mouseleave', =>
			@hideLine()
		,false)
		0